import { AnimLoopEngine } from 'anim-loop-engine';
import { Star } from './Star';
import { defaultColor } from './starColor';
// StarField factory
var StarField = /** @class */ (function () {
    function StarField(canvasId, opts) {
        var _this = this;
        if (opts === void 0) { opts = {}; }
        this.defaultMaxV = 5;
        this.defaultMinV = 2;
        this.defaultNumStars = 400;
        this.initialized = false;
        this.canvasW = 0;
        this.canvasH = 0;
        this.canvasHalfW = 0;
        this.canvasHalfH = 0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.offsetTX = 0;
        this.offsetTY = 0;
        this.stars = [];
        this.resizeTimeout = 0;
        if (!canvasId) {
            throw 'First argument "id" is required';
            return;
        }
        this.color = opts.color || defaultColor;
        this.glow = opts.glow || false;
        this.minV = opts.minV || this.defaultMinV;
        this.maxV = opts.maxV || this.defaultMaxV;
        this.numStars = this.defaultNumStars;
        this.trails = opts.trails || false;
        this.canvas = document.getElementById(canvasId);
        this.ctx = this.canvas.getContext('2d');
        var rect = this.canvas.getBoundingClientRect();
        this.canvasRectLeft = rect.left;
        this.canvasRectTop = rect.top;
        // Assign follow context now that this.canvas was assigned
        this.followContext = opts.followContext || this.canvas;
        this.handleMouseMove = this.handleMouseMove.bind(this);
        // Set up animation engine
        this.engine = new AnimLoopEngine();
        this.engine.addTask(this.draw.bind(this));
        // Set up window events
        // Window blur/focus
        window.addEventListener('blur', function () {
            _this.stop();
        });
        window.addEventListener('focus', function () {
            _this.start();
        });
        // Window event - on resize to reinitialize canvas, all stars and animation
        window.addEventListener('resize', function () {
            clearTimeout(_this.resizeTimeout);
            _this.stop();
            _this.resizeTimeout = setTimeout(function () {
                _this.reset();
                _this.start();
            }, 500);
        });
        // Did config set a number of stars?
        this.numStars = opts.numStars
            ? Math.abs(opts.numStars)
            : this.defaultNumStars;
        // Setup the canvas
        this.setupCanvas();
        // Gen new stars
        this.generateStars();
        this.initialized = true;
        // Did config enable mouse following?
        if (opts.followMouse) {
            this.setFollowMouse(true);
        }
    }
    // Generate n new stars
    StarField.prototype.generateStars = function () {
        for (var i = 0; i < this.numStars; i++) {
            this.stars.push(new Star({
                ctx: this.ctx,
                W: this.canvasW,
                H: this.canvasH,
                hW: this.canvasHalfW,
                hH: this.canvasHalfH,
                minV: this.minV,
                maxV: this.maxV,
                color: this.color,
                glow: this.glow,
                trails: this.trails,
                addTasks: this.engine.addTasks
            }));
        }
    };
    // Apply canvas container size to canvas and translate origin to center
    StarField.prototype.setupCanvas = function () {
        var canvasStyle = window.getComputedStyle(this.canvas);
        this.canvas.setAttribute('height', canvasStyle.height);
        this.canvas.setAttribute('width', canvasStyle.width);
        // canvasH/W/canvasHalfH/W used here and set to use elsewhere
        this.canvasH = this.canvas.height;
        this.canvasW = this.canvas.width;
        this.canvasHalfH = this.canvasH / 2;
        this.canvasHalfW = this.canvasW / 2;
        this.ctx.translate(this.canvasHalfW, this.canvasHalfH);
    };
    // Draw the stars in this frame
    StarField.prototype.draw = function () {
        // Adjust offsets closer to target offset
        if (this.offsetX !== this.offsetTX) {
            this.offsetX += (this.offsetTX - this.offsetX) * 0.02;
            this.offsetY += (this.offsetTY - this.offsetY) * 0.02;
        }
        // Clear the canvas ready for this frame
        this.ctx.clearRect(-this.canvasHalfW, -this.canvasHalfH, this.canvasW, this.canvasH);
        for (var i in this.stars) {
            this.stars[i].draw(this.offsetX, this.offsetY);
        }
    };
    // Follow mouse (used in event listener definition)
    StarField.prototype.handleMouseMove = function (e) {
        if (this.initialized) {
            this.offsetTX = e.clientX - this.canvasRectLeft - this.canvasHalfW;
            this.offsetTY = e.clientY - this.canvasRectTop - this.canvasHalfH;
        }
    };
    StarField.prototype.resetMouseOffset = function () {
        this.offsetTX = 0;
        this.offsetTY = 0;
    };
    // Start/stop the StarField
    StarField.prototype.start = function () {
        this.engine.start();
    };
    StarField.prototype.stop = function () {
        this.engine.stop();
    };
    StarField.prototype.reset = function () {
        // Clear stars
        this.stars = [];
        // Reset canvas
        this.setupCanvas();
        // Gen new stars
        this.generateStars();
    };
    // "Hot"-updateable config values
    StarField.prototype.setMaxV = function (val) {
        this.maxV = val ? Math.abs(val) : this.defaultMaxV;
        this.reset();
    };
    StarField.prototype.setMinV = function (val) {
        this.minV = val ? Math.abs(val) : this.defaultMinV;
        this.reset();
    };
    StarField.prototype.setNumStars = function (val) {
        this.numStars = val ? Math.abs(val) : this.defaultNumStars;
        this.reset();
    };
    StarField.prototype.setFollowMouse = function (val) {
        if (val) {
            this.followContext.addEventListener('mousemove', this.handleMouseMove);
        }
        else {
            this.followContext.removeEventListener('mousemove', this.handleMouseMove);
            this.resetMouseOffset();
        }
    };
    return StarField;
}());
export { StarField };
